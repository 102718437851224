import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Case } from '../../sections/index'

import Layout from '../../layout/Layout'
import Seo from '../../components/Seo'
import hero from '../../images/banners/allency-banner-briefcase.png'
import SmallHeroBand from '../../sections/SmallHeroBand'
import { SmallBand } from '../../sections/BandSection'


const IndexPage = () => {
  const dataImage = useStaticQuery(graphql`
    query {

      caseOne: file(relativePath: { eq: "stock/burger-fest-1.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }

    }
  `)

  const caseOne = dataImage.caseOne.childImageSharp.gatsbyImageData

  const contents = {
    hero: {
      image: hero,
      title: 'Portafolio',
    },
    case: {
      title: 'Burger Fest',
      services: 'Mkt digital',
      description: `El evento en Monterrey donde los mejores restaurantes de hamburguesas se reúnen con Food Trucks y divertidas amenidades. Nos encargamos de llevar a cabo las estrategias en redes sociales, campañas y programación de contenido de la página de Burger Fest para la edición que se realizó el 2019 en el Parque Fundidora.`,
      images: [caseOne ]
    }
  }
  return (
    <Layout>
      <Seo
        lang={'es'}
        title={'Allency'}
        description={"Agencia de publicidad"}
        keywords={['Publicidad,Aliados,Estrategia,Marketing,Agencia,Digital,Resultados,Branding,Naming,Logotipo,Brochure,Creativo,Plan,Medios,Campañas,Programación,Código,Posicionamiento,Tráfico,Interfaces,Gestión,Multimedia,Diseño,Proyecto,Planes,Soluciones,Calidad,Startups,Pymes,Empresa,Eventos,Corporativos,Marcas,Portafolio']}
        noIndex={''}
        noFollow={''}
        slug={'burger-fest'}
        canonicalUrl={'https://allency.com.mx/portfolio/burger-fest'}
        ogTitle={'Allency'}
        ogType={'website'}
        ogDescription={"Agencia de publicidad"}
        ogImage={'../../images/logos/allency-dark.png'}
      />

      <SmallHeroBand contents={contents.hero} headerSpace={true} />
      <Case contents={contents.case} />
      <SmallBand />
    </Layout>
  )
}

export default IndexPage